import { Card, Col, List, Row, Typography } from "antd";
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Helmet } from "react-helmet";

import React from "react";
import Wrapper from "../Components/Wrapper/Wrapper";

import data from "../Data/Italia/italia";

const Italia = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>Landi S.p.A. - Italia</title>
      </Helmet>
      <Row justify="center">
          <Col>
            <Typography.Title style={{textAlign: 'center'}}>Realizzazioni Italia</Typography.Title>
            <div style={{display: "block", width: "70px", height: "4px", margin: "0 auto", marginBottom: "10px", background: "rgb(248, 152, 40)"}}></div>
          </Col>
      </Row>
      <Row justify="center" style={{ marginTop:'20px' }}>
        <Col xxl={14} xl={18} lg={24} md={16} sm={18} xs={24} style={{height: '100%'}}>
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 2,
              xxl: 3,
            }}
            dataSource={data.sort((i,j) => {return j.dataFine - i.dataFine})}
            renderItem={(item) => (
              <List.Item>
                <Card 
                  bordered
                  style={{ objectFit: "cover" }}
                  title={item.committente}
                  cover={<img alt={item.title} src={item.imgPath} style={{width: '100%', height: '20vw', minHeight: '200px', objectFit: 'cover'}}/>}
                  actions={[
                    <a href={item.pdfPath} target="_blank" rel="noreferrer"><FilePdfOutlined key="setting" style={{fontSize: 30}}  /></a>,
                    <a href={item.pdfPath} download><DownloadOutlined key="download" style={{fontSize: 30}}  /></a>,
                  ]}
                >
                  <Card.Meta
                    title={item.title}
                    description={item.descrizione}
                  />
                  {item.dataInizio && <Row justify="center" style={{marginTop: '15px'}}>{item.dataInizio} - {item.dataFine}</Row>}
                  
                </Card>
              </List.Item>
            )}
          />
          </Col>
      </Row>
    </Wrapper>
  );
}

export default Italia;