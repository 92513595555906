import React from "react";
import CookieConsent from "react-cookie-consent";
import { Button, Col, Divider, Drawer, Layout, Menu, Row } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./Wrapper.css";
import logo from "../../Assets/Images/logo_landi_daikin.png";
import logocsq from "../../Assets/Images/IMQ.jpg";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import cookies from "../../Assets/certificazioni/cookies.pdf";

const { Header, Content, Footer } = Layout;

const Wrapper = (props) => {
  const navigate = useNavigate();

  const [drawerVisible, setDrawerVisible] = React.useState(false);

  return (
    <Layout>
      <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
        <div
          className="logo"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer", paddingTop: 10 }}
        >
          <img src={logo} alt="logo" style={{ width: "100%" }} />
        </div>
        <Button
          className="menubtn"
          type="primary"
          icon={<MenuOutlined />}
          onClick={() => setDrawerVisible(!drawerVisible)}
        />
        <Drawer
          closeIcon={false}
          title={<img src={logo} alt="logo" style={{ height: 50 }} />}
          placement="right"
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          footer={
            <Col>
              <Row justify="center">
                <img src={logocsq} alt="logo" style={{ width: 100 }} />
              </Row>
              <Row justify="center" style={{ marginTop: 15 }}>
                Landi SpA ©2022
              </Row>
            </Col>
          }
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              type="text"
              onClick={() => {
                navigate("/");
                setDrawerVisible(false);
              }}
            >
              Home
            </Button>
            <Divider style={{ margin: "10px 0" }} />
            <Button
              type="text"
              onClick={() => {
                navigate("/italia");
                setDrawerVisible(false);
              }}
            >
              Realizzazioni Italia
            </Button>
            <Divider style={{ margin: "10px 0" }} />
            <Button
              type="text"
              onClick={() => {
                navigate("/estero");
                setDrawerVisible(false);
              }}
            >
              {" "}
              Realizzazioni Estero
            </Button>
            <Divider style={{ margin: "10px 0" }} />
            {/* <Button type="text" onClick={() => window.location.href=AREA_RISERVATA_URL} >Area Riservata</Button> */}
            {/* <Divider style={{margin: '10px 0'}}/> */}
          </div>
        </Drawer>
        <Menu
          className="bigmenu"
          selectable={false}
          defaultSelectedKeys={[]}
          theme="light"
          mode="horizontal"
          style={{ display: "block" }}
          overflowedIndicator={
            <MenuOutlined onClick={() => setDrawerVisible(!drawerVisible)} />
          }
        >
          <Menu.Item key="1" onClick={() => navigate("/")}>
            Home
          </Menu.Item>
          <Menu.SubMenu key="2" title="Realizzazioni">
            <Menu.Item key="21" onClick={() => navigate("/italia")}>
              Italia
            </Menu.Item>
            <Menu.Item key="22" onClick={() => navigate("/estero")}>
              Estero
            </Menu.Item>
          </Menu.SubMenu>
          {/* <Menu.Item key="3" onClick={() => window.location.href=AREA_RISERVATA_URL}>Area Riservata</Menu.Item> */}
          <Menu.Item
            disabled
            key="4"
            style={{ float: "right", paddingTop: 10 }}
          >
            <img src={logocsq} alt="logo" style={{ height: 50 }} />
          </Menu.Item>
        </Menu>
      </Header>
      <Content
        className="site-layout"
        style={{ padding: "24px", marginTop: 94, background: "#EAEAEA" }}
      >
        {props.children}
      </Content>
      <Footer style={{ textAlign: "center" }}>Landi SpA ©2022</Footer>
      <CookieConsent
        enableDeclineButton
        onDecline={() => {
          Cookies.set("CookieConsent", "false", { expires: 1 });
        }}
        location="bottom"
        buttonText="Accetto"
        declineButtonText="Rifiuto"
        onAccept={() => {
          Cookies.set("CookieConsent", "true");
          window.location.reload();
        }}
        buttonStyle={{
          background: "rgb(0, 94, 155)",
          color: "white",
          fontSize: "13px",
        }}
        // decline button color
      >
        Questo sito fa uso di cookie per migliorare l'esperienza di navigazione
        degli utenti e per raccogliere informazioni sull'utilizzo del sito
        stesso. Utilizziamo sia cookie tecnici sia cookie di parti terze per
        inviare messaggi promozionali sulla base dei comportamenti degli utenti.
        Può conoscere i dettagli consultando la nostra{" "}
        <a href={cookies} target="_blank" rel="noreferrer">
          informativa cookies
        </a>{" "}
        qui. Proseguendo nella navigazione si accetta l'uso dei cookie; in caso
        contrario è possibile abbandonare il sito.
      </CookieConsent>
    </Layout>
  );
};

export default Wrapper;
