import * as files from "./files";

const data = [
    {
        nazione: "Turkmenistan",
        realizzazioni: [
            {
                title: 'Accademia Militare - Ashgabat (TURKMENISTAN)',
                imgPath: files.accmil,
                pdfPath: files.accmilPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Progettazione elettrica esecutiva, costruttiva e di dettaglio, lighting, procurement, purchasing, commissioning",
                dataInizio: 2008,
                dataFine: 2009
            },
            {
                title: 'Centro Conferenze - Ashgabat (TURKMENISTAN)',
                imgPath: files.cc,
                pdfPath: files.ccPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Fornitura e supervisione all’installazione di impianti elettrici e speciali",
                dataInizio: 2018,
                dataFine: 2020
            },
            {
                title: 'Centro Congressi - Ashgabat (TURKMENISTAN)',
                imgPath: files.ccc,
                pdfPath: files.cccPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Intervento effettuato e caratteristiche Fornitura di celle di media tensione, blindosbarre, trasformatori, generatori, power centers, quadri di distribuzione e UPS. On-site commissioning, start-up, consulenza generale del progetto elettrico e supervisione all’installazione",
                dataInizio: 2013,
                dataFine: 2014
            },
            {
                title: 'IRI - Ashgabat (TURKMENISTAN)',
                imgPath: files.iri,
                pdfPath: files.iriPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Progettazione e elettrica esecutiva, costruttiva e di dettaglio, lighting, procurement, purchasing, realizzazione degli impianti elettrici e commissioning",
                dataInizio: 2010,
                dataFine: 2011
            },
            {
                title: 'Sede Amministrativa del Ministero degli Affari Esteri - Ashgabat (TURKMENISTAN)',
                imgPath: files.affes,
                pdfPath: files.affesPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Progettazione elettrica esecutiva, costruttiva e di dettaglio, lighting, procurement, purchasing, commissioning",
                dataInizio: 2009,
                dataFine: 2010
            },
            {
                title: 'Ministero e Museo del Tappeto - Ashgabat (TURKMENISTAN)',
                imgPath: files.tap,
                pdfPath: files.tapPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Progettazione elettrica esecutiva, costruttiva e di dettaglio, lighting, procurement, purchasing, commissioning",
                dataInizio: 2008,
                dataFine: 2009
            },
            {
                title: 'Nuovo Quartier Generale Bouygues - Ashgabat (TURKMENISTAN)',
                imgPath: files.qg,
                pdfPath: files.qgPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Fornitura di n. 3 generatori a gas da 750kVA cadauno e N. 1 generatore a gas da 1000kVA. On-site commissioning e supervisione all’installazione",
                dataInizio: 2012,
                dataFine: 2013
            },
            {
                title: 'Ristrutturazione Palazzo Roukhiet - Ashgabat (TURKMENISTAN)',
                imgPath: files.pr,
                pdfPath: files.prPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Fornitura di celle di media tensione, trasformatori, power centers e UPS. On-site commissioning, start-up, consulenza generale del progetto elettrico e supervisione all’installazione",
                dataInizio: 2012,
                dataFine: 2012
            },
            {
                title: 'Sede del Partito Nazionale - Ashgabat (TURKMENISTAN)',
                imgPath: files.pn,
                pdfPath: files.pnPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Progettazione elettrica esecutiva, costruttiva e di dettaglio, lighting, procurement, purchasing, commissioning",
                dataInizio: 2009,
                dataFine: 2010
            },
            {
                title: 'IGP - Ashgabat (TURKMENISTAN)',
                imgPath: files.igp,
                pdfPath: files.igpPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Progettazione elettrica esecutiva, costruttiva e di dettaglio, lighting, procurement, purchasing, commissioning",
                dataInizio: 2009,
                dataFine: 2011
            },
            {
                title: 'Università Internazionale - Ashgabat (TURKMENISTAN)',
                imgPath: files.uni,
                pdfPath: files.uniPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Progetto chiavi in mano per la realizzazione di impianti elettrici e speciali",
                dataInizio: 2013,
                dataFine: 2014
            },
            {
                title: 'Facoltà di Chimica - Ashgabat (TURKMENISTAN)',
                imgPath: files.chi,
                pdfPath: files.chiPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Realizzazione di impianti elettrici e speciali per l’università 6, facoltà di chimica",
                dataInizio: 2016,
                dataFine: 2017
            },
            {
                title: 'Facoltà di Geografia - Ashgabat (TURKMENISTAN)',
                imgPath: files.geo,
                pdfPath: files.geoPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Progettazione elettrica esecutiva, costruttiva e di dettaglio, lighting, procurement, purchasing, commissioning",
                dataInizio: 2009,
                dataFine: 2011
            },
            {
                title: 'Villa Presidenziale - Awaza (TURKMENISTAN)',
                imgPath: files.villa,
                pdfPath: files.villaPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Progettazione elettrica esecutiva, costruttiva e di dettaglio, lighting, procurement, purchasing, commissioning",
                dataInizio: 2010,
                dataFine: 2011
            },
            {
                title: 'Banca Commerciale Turkmenbashy - Ashgabat (TURKMENISTAN)',
                imgPath: files.bank,
                pdfPath: files.bankPDF,
                committente: 'Bouygues Construction International',
                descrizione: "Fornitura e supervisione all'installazione di impianti elettrici e speciali",
                dataInizio: 2019,
                dataFine: 2020
            },
        ]
    },
    {
        nazione: "Svizzera",
        realizzazioni: [
            {
                title: 'BIT - Ginevra (CH)',
                imgPath: files.gin,
                pdfPath: files.ginPDF,
                committente: 'Bureau International du Travail',
                descrizione: "Realizzazione di n. 3 Power Centers per la sede del BIT a Ginevra (Svizzera)",
                dataInizio: 2015,
                dataFine: 2016
            },
        ]
    }
]

export default data;