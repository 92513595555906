import * as files from "./files";

const data = [
    {
        title: 'Centrale Ciclo Combinato - Leinì (TO).jpg',
        imgPath: files.ansaldo,
        pdfPath: files.ansaldoPDF,
        committente: 'Ansaldo Energia S.p.A',
        descrizione: "Montaggi Elettrostrumentali e Avviamento presso la Centrale a Ciclo Combinato 400MW di Leinì, Torino",
        dataInizio: 2006,
        dataFine: 2008
    },
    {
        title: 'Segreen Business Park - Segrate (MI)',
        imgPath: files.bovis,
        pdfPath: files.bovisPDF,
        committente: 'Bovis Lend Lease S.r.l',
        descrizione: 'ProgettazIone e realizzazione di impianti tecnologici HVAC, termoidraulici, elettrici e speciali per il complesso per uffici sito in via S. Bovio 3, località San Felice, Segrate (MI)',
        dataInizio: 2010,
        dataFine: 2011
    },
    {
        title: 'Anas Grande Viabilità - Trieste (TS)',
        imgPath: files.collini,
        pdfPath: files.colliniPDF,
        committente: 'Collini S.p.A',
        descrizione: 'Progettazione e realizzazione degli impianti elettrici, sistemi TVCC, Lighting e Automazione, relativi a 13 Km di gallerie, viadotti e svincoli in località Montedoro, Cattinara e Carso',
        dataInizio: 2007,
        dataFine: 2009
    },
    {
        title: 'Laika Caravans - San Casciano Val di Pesa (FI)',
        imgPath: files.colombo1,
        pdfPath: files.colombo1PDF,
        committente: 'Colombo Costruzioni S.p.A',
        descrizione: 'Realizzazione di impianti Elettrici e Speciali per il Nuovo Complesso Industriale LAIKA CARAVANS S.P.A., San Casciano Val di Pesa (FI)',
        dataInizio: 2015,
        dataFine: 2016
    },
    {
        title: 'Progetto Porta Garibaldi - Milano (MI)',
        imgPath: files.colombo2,
        pdfPath: files.colombo2PDF,
        committente: 'Colombo Costruzioni S.p.A',
        descrizione: 'Realizzazione di impianti Elettrici e Speciali relativi all’Edificio E1-E2 del Complesso denominato Porta Nuova Garibaldi, Via Don Luigi Sturzo 20-22, Milano',
        dataInizio: 2011,
        dataFine: 2013
    },
    {
        title: 'Progetto Porta Nuova Garibaldi - Milano (MI)',
        imgPath: files.colombo3,
        pdfPath: files.colombo3PDF,
        committente: 'Colombo Costruzioni S.p.A',
        descrizione: 'Progettazione e Realizzazione di impianti Elettrici e Speciali per Progetto Torre e Monolite, Via Kuliscioff, Milano',
        dataInizio: 2008,
        dataFine: 2011
    },
    {
        title: 'Davines Village - Roncopascolo (PR)',
        imgPath: files.davines,
        pdfPath: files.davinesPDF,
        committente: 'Davines S.p.A',
        descrizione: 'Realizzazione di impianti meccanici, elettrici e speciali per il nuovo edificio “DAVINES VILLAGE” e gli impianti industriali di processo in Roncopascolo (PR)',
        dataInizio: 2017,
        dataFine: 2018
    },
    {
        title: 'Sede Legale Deloitte - Milano (MI)',
        imgPath: files.deloitte,
        pdfPath: files.deloittePDF,
        committente: 'Deloitte Touche Tohmatsu S.p.A',
        descrizione: 'Progettazione e Realizzazione chiavi in mano di impianti tecnologici HVAC,  termoidraulici, Elettrici e Speciali per Sede Legale, Via Bergognone, Milano',
        dataInizio: 2002,
        dataFine: 2004
    },
    {
        title: 'Punti Vendita Esselunga',
        imgPath: files.esselunga,
        pdfPath: files.esselungaPDF,
        committente: 'Esselunga S.p.A',
        descrizione: 'Progettazione e Realizzazione chiavi in mano di impianti tecnologici HVAC, termoidraulici, Elettrici e Speciali presso vari supermercati',
        dataInizio: 2003,
        dataFine: 2003
    },
    {
        title: 'Edifici Residenziali - Milano (MI)',
        imgPath: files.generali,
        pdfPath: files.generaliPDF,
        committente: 'Generali S.p.A',
        descrizione: 'Realizzazione di Impianti Elettrici, Speciali, HVAC e Termoidraulici per nuovo complesso residenziale',
        dataInizio: 2012,
        dataFine: 2015
    },
    {
        title: 'Milanofiori 2000 - Assago (MI)',
        imgPath: files.milanofiori,
        pdfPath: files.milanofioriPDF,
        committente: 'Gruppo Brioschi - MILANOFIORI 2000',
        descrizione: 'Progettazione e Realizzazione chiavi in mano di impianti tecnologici HVAC,termoidraulici, Elettrici e Speciali per Comparto D - Edifici U9 e U10, Assago (MI)',
        dataInizio: 2007,
        dataFine: 2009
    },
    {
        title: 'Campus Universitario Humanitas - Pieve Emanuele (MI)',
        imgPath: files.humanitas1,
        pdfPath: files.humanitas1PDF,
        committente: 'Humanitas University',
        descrizione: 'Design e realizzazione di impianti meccanici, elettrici e speciali per il nuovo edificio ad uso accoglienza all’interno del campus universitario Humanitas in Pieve Emanuele (MI)',
        dataInizio: 2017,
        dataFine: 2018
    },
    {
        title: 'Istituto Clinico Humanitas AREA VENDER 1 e 2 - Pieve Emanuele (MI)',
        imgPath: files.humanitas2,
        pdfPath: files.humanitas2PDF,
        committente: 'Intertecno S.p.A',
        descrizione: 'Progettazione e Realizzazione chiavi in mano di impianti tecnologici HVAC, termoidraulici, Elettrici e Speciali',
        dataInizio: 2010,
        dataFine: 2012
    },
    {
        title: 'Bricoman - Dalmine (BG)',
        imgPath: files.bricoman,
        pdfPath: files.bricomanPDF,
        committente: 'Poledil S.r.l',
        descrizione: 'Realizzazione Impianti Meccanici, Elettrici e Speciali presso il nuovo punto vendita BRICOMAN sito in via Vailetta snc, Dalmine (BG)',
        dataInizio: 2014,
        dataFine: 2014
    },
    {
        title: 'Centrale a Ciclo Combinato - Livorno Ferraris (VC)',
        imgPath: files.siemens,
        pdfPath: files.siemensPDF,
        committente: 'Siemens S.p.A',
        descrizione: 'Realizzazione di impianti tecnologici elettrostrumentali, elettrici, speciali e sistemi TVCC e Lighting',
        dataInizio: 2007,
        dataFine: 2008
    },
    {
        title: 'Progetto Fermi Elettra - Interconnessione fluidistica ed elettrica - Basovizza (TS)',
        imgPath: files.sincro1,
        pdfPath: files.sincro1PDF,
        committente: 'Sincrotrone S.C.p.A',
        descrizione: 'Progettazione e Realizzazione chiavi in mano di impianti tecnologici HVAC, termoidraulici, Elettrici e Speciali, e realizzazione in Project Financing della seconda Centrale di Trigenerazione da 1,5 MW',
        dataInizio: 2011,
        dataFine: 2012
    },
    {
        title: 'Progetto Fermi Elettra - Prima Centrale - Basovizza (TS)',
        imgPath: files.sincro2,
        pdfPath: files.sincro2PDF,
        committente: 'Sincrotrone S.C.p.A',
        descrizione: 'Progettazione e Realizzazione chiavi in mano di impianti tecnologici HVAC, termoidraulici, Elettrici e Speciali, e realizzazione in Project Financing della prima Centrale di Trigenerazione da 1,5 MW',
        dataInizio: 2009,
        dataFine: 2011
    },
    {
        title: 'Clinica Santa Rita - Milano (MI)',
        imgPath: files.santarita,
        pdfPath: files.santaritaPDF,
        committente: 'Spazio Immobiliare 2000 S.r.l',
        descrizione: 'Progettazione e Realizzazione chiavi in mano di impianti tecnologici HVAC, termoidraulici, Elettrici e Speciali',
        dataInizio: 2003,
        dataFine: 2005
    },
    {
        title: 'Centro Natatorio - Almè (BG)',
        imgPath: files.alme,
        pdfPath: files.almePDF,
        committente: 'Stile Nuoto Quadri S.r.l',
        descrizione: 'Realizzazione di Impianti Meccanici',
        dataInizio: 2012,
        dataFine: 2013
    },
    {
        title: 'Humanitas Centro Oncologico del Mediterraneo - Misterbianco (CT)',
        imgPath: files.cat,
        pdfPath: files.catPDF,
        committente: 'Humanitas Centro Catanese di Oncologia R.E.',
        descrizione: 'Design e realizzazione di impianti elettrici e speciali per il nuovo centro oncologico del mediterraneo Humanitas di Misterbianco (CT)',
        dataInizio: 2019,
        dataFine: 2019
    },
    {
        title: 'Riqualificazione immobile di via Ripamonti 85 - Milano',
        imgPath: files.coima,
        pdfPath: files.coimaPDF,
        committente: 'Coima SGR',
        descrizione: 'Realizzazione di impianti elettrici, speciali, meccanici ed antincendio c/o l’edificio di Via Ripamonti 85, Milano',
        dataInizio: 2019,
        dataFine: 2020
    },
    {
        title: 'Nuovo Studentato Via Giovenale - Milano',
        imgPath: files.hines,
        pdfPath: files.hinesPDF,
        committente: 'Hines SGR',
        descrizione: 'Realizzazione di impianti elettrici e speciali per il nuovo studentato di via Giovenale, Milano',
        dataInizio: 2021,
        dataFine: 2021
    },
    {
        title: 'Nuovo Polo Logistico DHL - Borgo San Giovanni (LO)',
        imgPath: files.dhl,
        pdfPath: files.dhlPDF,
        committente: 'PROP.CO SRL',
        descrizione: 'Realizzazione di impianti elettrici, speciali, idrico-sanitari, antincendio e HVAC c/o il nuovo polo logistico DHL di Borgo San Giovanni (LO)',
        dataInizio: 2020,
        dataFine: 2021
    },
    {
        title: 'Nuovo Ospedale di Fermo - Fermo',
        imgPath: files.fermo,
        pdfPath: files.fermoPDF,
        committente: 'Regione Marche',
        descrizione: 'Appalto integrato per la progettazione esecutiva e l\'esecuzione dei lavori della nuova struttura ospedaliera di fermo',
        dataInizio: 2020,
        dataFine: 2023
    },
];

export default data;