import React from "react";
import Wrapper from "../Components/Wrapper/Wrapper";
import { Card, Col, Divider, List, Row, Typography } from "antd";
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Helmet } from "react-helmet";

import data from "../Data/Estero/estero";

const Estero = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>Landi S.p.A. - Estero</title>
      </Helmet>
      <Row justify="center">
            <Col>
              <Typography.Title style={{textAlign: 'center'}}>Realizzazioni Estero</Typography.Title>
              <div style={{display: "block", width: "70px", height: "4px", margin: "0 auto", marginBottom: "10px", background: "rgb(248, 152, 40)"}}></div>
            </Col>
      </Row>
      <Divider />
      {data.map((item, index) => (
        <>
          <Row justify="center">
              <Col>
                <Typography.Title style={{textAlign: 'center'}} level={2}>{item.nazione}</Typography.Title>
              </Col>
          </Row>
          <Row justify="center" style={{ marginTop:'20px' }}>
            <Col xxl={14} xl={18} lg={24} md={16} sm={18} xs={24} style={{height: '100%'}}>
              <List
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 2,
                  xl: 2,
                  xxl: 3,
                }}
                dataSource={item.realizzazioni.sort((i,j) => {return j.dataFine - i.dataFine})}
                renderItem={(r) => (
                    <List.Item>
                      <Card 
                        bordered
                        style={{ objectFit: "cover" }}
                        title={r.committente}
                        cover={<img alt={r.title} src={r.imgPath} style={{width: '100%', height: '20vw', minHeight: '200px', objectFit: 'cover'}}/>}
                        actions={[
                          <a href={r.pdfPath} target="_blank" rel="noreferrer"><FilePdfOutlined key="setting" style={{fontSize: 30}}  /></a>,
                          <a href={item.pdfPath} download><DownloadOutlined key="download" style={{fontSize: 30}}  /></a>
                        ]}
                      >
                        <Card.Meta
                          title={r.title}
                          description={r.descrizione}
                        />
                        {r.dataInizio && <Row justify="center" style={{marginTop: '15px'}}>{r.dataInizio} - {r.dataFine}</Row>}
                        
                      </Card>
                    </List.Item>
                  )}
              />
            </Col>
          </Row>
          <Divider />
        </>
    ))}
    </Wrapper>
  );
}

export default Estero;