import {
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Image,
  List,
  Row,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
import Cookies from "js-cookie";

import Wrapper from "../Components/Wrapper/Wrapper";
import carousel1 from "../Assets/Images/Realizzazioni/Italia/Humanitas University/Campus Universitario Humanitas - Pieve Emanuele (MI).jpg";
import carousel2 from "../Assets/Images/Realizzazioni/Italia/Davines S.p.A/Davines Village - Roncopascolo (PR).jpg";
import carousel3 from "../Assets/Images/Realizzazioni/Italia/2021_Prop.Co._Polo_Logistico_DHL/DHLcarousel.jpg";
import carousel4 from "../Assets/Images/Realizzazioni/Estero/Turkmenistan/Universita Internazionale/Universita Internazionale - Ashgabat (TURKMENISTAN).jpg";
import ext from "../Assets/Images/ext.png";
import serv1 from "../Assets/Images/imp luce 023.jpg";
import serv2 from "../Assets/Images/progettazione.jpg";
import serv3 from "../Assets/Images/manutenzione.png";
import real1 from "../Assets/Images/rtaImage.jpg";
import real2 from "../Assets/Images/Museo.jpg";

import cert1 from "../Assets/certificazioni/CODICE DISCIPLINARE.pdf";
import cert2 from "../Assets/certificazioni/CODICE ETICO.pdf";
import cert3 from "../Assets/certificazioni/F-GAS.pdf";
import cert4 from "../Assets/certificazioni/ISO 9001.pdf";
import cert5 from "../Assets/certificazioni/MODELLO ORGANIZZAZIONE, GESTIONE E CONTROLLO.pdf";
import cert6 from "../Assets/certificazioni/POLITICA AZIENDALE.pdf";
import cert7 from "../Assets/certificazioni/SOA.pdf";
import cert8 from "../Assets/certificazioni/WHISTLEBLOWER.pdf";
// import cert9 from "../Assets/certificazioni/IMQ.pdf";

const { Meta } = Card;

const Home = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState(
    "Campus Universitario Humanitas - Pieve Emanuele (MI).jpg"
  );
  const [description, setDescription] = useState(
    "Humanitas University Campus - Edificio Accoglienza"
  );
  const [cookie, setCookie] = useState(Cookies.get("CookieConsent"));

  useEffect(() => {
    setCookie(Cookies.get("CookieConsent"));
  }, []);

  const onChange = (currentSlide) => {
    switch (currentSlide) {
      case 0:
        setTitle("Campus Universitario Humanitas - Pieve Emanuele (MI).jpg");
        setDescription("Humanitas University Campus - Edificio Accoglienza");
        break;
      case 1:
        setTitle("Davines Village - Roncopascolo (PR)");
        setDescription(
          "Realizzazione del nuovo Davines Village, edificio a destinazione mista"
        );
        break;
      case 2:
        setTitle("Nuovo Polo Logistico DHL - Borgo San Giovanni (LO)");
        setDescription("Nuovo Polo Logistico DHL di Via Padre Pio");
        break;
      case 3:
        setTitle("Università Internazionale - Ashgabat (TURKMENISTAN)");
        setDescription(
          "Progetto chiavi in mano per la realizzazione di impianti elettrici e speciali"
        );
        break;
      default:
        setTitle("");
    }
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Landi S.p.A. - Home</title>
      </Helmet>
      <Row justify="center" align="middle">
        <Col xxl={10} xl={16} lg={24} md={24} sm={24} xs={24}>
          <Carousel
            autoplay
            style={{ textAlign: "center" }}
            afterChange={onChange}
          >
            <img
              src={carousel1}
              alt="1"
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
            <img
              src={carousel2}
              alt="2"
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
            <img
              src={carousel3}
              alt="3"
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
            <img
              src={carousel4}
              alt="4"
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
          </Carousel>
        </Col>
      </Row>

      <Row justify="center" style={{ marginTop: "20px", textAlign: "center" }}>
        <Typography.Title level={4}>{title}</Typography.Title>
      </Row>
      <Row justify="center" style={{ textAlign: "center" }}>
        <Typography.Text>{description}</Typography.Text>
      </Row>
      <Divider />
      <Row justify="center" style={{ marginTop: "50px" }}>
        <Col>
          <Typography.Title>Chi Siamo</Typography.Title>
          <div
            style={{
              display: "block",
              width: "70px",
              height: "4px",
              margin: "0 auto",
              marginBottom: "10px",
              background: "rgb(248, 152, 40)",
            }}
          ></div>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Col xxl={8} xl={8} lg={16} md={16} sm={24} xs={24}>
          <Typography.Paragraph style={{ textAlign: "center", fontSize: 16 }}>
            <strong>Landi S.p.A.</strong> opera nei settori industriale,
            commerciale e terziario e fornisce servizi e consulenze di elevata
            qualità nell'ambito della progettazione e realizzazione di{" "}
            <strong>
              impianti elettrici, meccanici e termotecnici, quadri elettrici
            </strong>{" "}
            e nella gestione dei processi di facility management.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Image src={ext} alt="esterno" preview={false} />
      </Row>
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Col xxl={8} xl={8} lg={16} md={16} sm={24} xs={24}>
          <Typography.Paragraph style={{ textAlign: "center", fontSize: 16 }}>
            <strong>Fondata nel 1976</strong> per operare nell'ambito delle reti
            elettriche, <strong>Landi S.p.A.</strong> ha esteso nel tempo il
            proprio campo di azione alla produzione di{" "}
            <strong>quadri elettrici</strong>, all'
            <strong>impiantistica termotecnica e meccanica</strong>, maturando
            una qualificata esperienza nella progettazione.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Divider />
      <Row justify="center" style={{ marginTop: "50px" }}>
        <Col>
          <Typography.Title>Servizi</Typography.Title>
          <div
            style={{
              display: "block",
              width: "70px",
              height: "4px",
              margin: "0 auto",
              marginBottom: "10px",
              background: "rgb(248, 152, 40)",
            }}
          ></div>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: "20px" }} gutter={[10, 10]}>
        <Col xxl={5} xl={8} lg={12} md={16} sm={24} xs={24}>
          <Card cover={<img alt="example" src={serv1} />}>
            <Meta title="IMPIANTI" style={{ marginBottom: "10px" }} />
            <div
              style={{
                display: "block",
                width: "70px",
                height: "4px",
                margin: "0 auto",
                marginBottom: "10px",
                background: "rgb(248, 152, 40)",
              }}
            ></div>
            <Typography.Paragraph
              style={{ textAlign: "center", fontSize: 16, marginTop: "20px" }}
            >
              <strong>Landi S.p.A.</strong> offre una vasta gamma di soluzioni
              all'avanguardia di qualsiasi tipo di impianto tecnologico,
              realizzato sin dalla nascita da un team altamente specializzato
            </Typography.Paragraph>
          </Card>
        </Col>
        <Col xxl={5} xl={8} lg={12} md={16} sm={24} xs={24}>
          <Card cover={<img alt="example" src={serv2} />}>
            <Meta title="PROGETTI" style={{ marginBottom: "10px" }} />
            <div
              style={{
                display: "block",
                width: "70px",
                height: "4px",
                margin: "0 auto",
                marginBottom: "10px",
                background: "rgb(248, 152, 40)",
              }}
            ></div>
            <Typography.Paragraph
              style={{ textAlign: "center", fontSize: 16, marginTop: "20px" }}
            >
              Sarete sempre affiancati da un'eccellente organizzazione che
              garantisce alla propria clientela una soluzione chiavi in mano e
              alla capacità di realizzare l'opera partendo dall'impostazione
              progettuale realizzata in azienda
            </Typography.Paragraph>
          </Card>
        </Col>
        <Col xxl={5} xl={8} lg={12} md={16} sm={24} xs={24}>
          <Card cover={<img alt="example" src={serv3} />}>
            <Meta title="MANUTENZIONE" style={{ marginBottom: "10px" }} />
            <div
              style={{
                display: "block",
                width: "70px",
                height: "4px",
                margin: "0 auto",
                marginBottom: "10px",
                background: "rgb(248, 152, 40)",
              }}
            ></div>
            <Typography.Paragraph
              style={{ textAlign: "center", fontSize: 16, marginTop: "20px" }}
            >
              <strong>Landi Facility Management</strong> si propone come
              interlocutore per aziende che intendono affidare a un partner
              qualificato il corretto funzionamento di impianti tecnologici
            </Typography.Paragraph>
          </Card>
        </Col>
      </Row>
      <Divider />
      <Row justify="center" style={{ marginTop: "50px" }}>
        <Col>
          <Typography.Title>Realizzazioni</Typography.Title>
          <div
            style={{
              display: "block",
              width: "70px",
              height: "4px",
              margin: "0 auto",
              marginBottom: "10px",
              background: "rgb(248, 152, 40)",
            }}
          ></div>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: "20px" }} gutter={[10, 10]}>
        <Col xxl={6} xl={8} lg={12} md={16} sm={24} xs={24}>
          <Card
            hoverable
            style={{ cursor: "default" }}
            cover={
              <img
                alt="realizzazioni italia"
                src={real1}
                style={{ width: "100%", height: "30vw", objectFit: "cover" }}
              />
            }
          >
            <Meta title="ITALIA" style={{ marginBottom: "10px" }} />
            <div
              style={{
                display: "block",
                width: "70px",
                height: "4px",
                margin: "0 auto",
                marginBottom: "10px",
                background: "rgb(248, 152, 40)",
              }}
            ></div>
            <Typography.Paragraph
              style={{ textAlign: "center", fontSize: 16, marginTop: "20px" }}
            >
              Scopri le realizzazioni di <strong>Landi S.p.A.</strong> in{" "}
              <strong>Italia</strong>
            </Typography.Paragraph>
            <Row justify="center" style={{ marginTop: 30 }}>
              <Button
                size="large"
                type="primary"
                onClick={() => navigate("/italia")}
              >
                Scopri di più
              </Button>
            </Row>
          </Card>
        </Col>
        <Col xxl={6} xl={8} lg={12} md={16} sm={24} xs={24}>
          <Card
            hoverable
            style={{ cursor: "default" }}
            cover={
              <img
                alt="realizzazioni estero"
                src={real2}
                style={{ width: "100%", height: "30vw", objectFit: "cover" }}
              />
            }
          >
            <Meta title="ESTERO" style={{ marginBottom: "10px" }} />
            <div
              style={{
                display: "block",
                width: "70px",
                height: "4px",
                margin: "0 auto",
                marginBottom: "10px",
                background: "rgb(248, 152, 40)",
              }}
            ></div>
            <Typography.Paragraph
              style={{ textAlign: "center", fontSize: 16, marginTop: "20px" }}
            >
              Scopri le realizzazioni di <strong>Landi S.p.A.</strong> all'
              <strong>Estero</strong>
            </Typography.Paragraph>
            <Row justify="center" style={{ marginTop: 30 }}>
              <Button
                size="large"
                type="primary"
                onClick={() => navigate("/estero")}
              >
                Scopri di più
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
      <Divider />
      <Row justify="center" style={{ marginTop: "50px" }}>
        <Col>
          <Typography.Title>Certificazioni</Typography.Title>
          <div
            style={{
              display: "block",
              width: "70px",
              height: "4px",
              margin: "0 auto",
              marginBottom: "10px",
              background: "rgb(248, 152, 40)",
            }}
          ></div>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Col xxl={6} xl={8} lg={12} md={16} sm={24} xs={24}>
          <List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                title={
                  <a href={cert1} target="_blank" rel="noreferrer">
                    CODICE DISCIPLINARE
                  </a>
                }
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={
                  <a href={cert2} target="_blank" rel="noreferrer">
                    CODICE ETICO
                  </a>
                }
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={
                  <a href={cert3} target="_blank" rel="noreferrer">
                    F-GAS
                  </a>
                }
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={
                  <a href={cert4} target="_blank" rel="noreferrer">
                    ISO 9001
                  </a>
                }
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={
                  <a href={cert5} target="_blank" rel="noreferrer">
                    MODELLO ORGANIZZAZIONE, GESTIONE E CONTROLLO
                  </a>
                }
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={
                  <a href={cert6} target="_blank" rel="noreferrer">
                    POLITICA AZIENDALE
                  </a>
                }
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={
                  <a href={cert7} target="_blank" rel="noreferrer">
                    SOA
                  </a>
                }
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={
                  <a
                    href="https://www.daikin.it/it_it/privati/data-protection-policy.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    INFORMATIVA COOKIES
                  </a>
                }
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={
                  <a
                    href={cert8}
                    target="_blank"
                    rel="noreferrer"
                    className="ant-btn ant-btn-primary ant-btn-lg"
                    style={{ color: "white" }}
                  >
                    Politica sulla protezione dei dati personali
                  </a>
                }
              />
            </List.Item>
            {/* <List.Item>
              <List.Item.Meta
                title={
                  <a href={cert8} target="_blank" rel="noreferrer">
                    ETICA AZIENDALE
                  </a>
                }
              />
            </List.Item> */}
            {/* <List.Item>
              <List.Item.Meta
                title={
                  <a href={cert9} target="_blank" rel="noreferrer">
                    IMQ
                  </a>
                }
              />
            </List.Item> */}
          </List>
        </Col>
      </Row>
      <Divider />
      <Row justify="center" style={{ marginTop: "50px" }}>
        <Col>
          <Typography.Title>Contattaci</Typography.Title>
          <div
            style={{
              display: "block",
              width: "70px",
              height: "4px",
              margin: "0 auto",
              marginBottom: "10px",
              background: "rgb(248, 152, 40)",
            }}
          ></div>
        </Col>
      </Row>
      <Row
        justify="center"
        align="middle"
        style={{ marginTop: "20px" }}
        gutter={[20, 20]}
      >
        {cookie === "true" ? (
          <Col
            xxl={8}
            xl={12}
            lg={16}
            md={18}
            sm={24}
            xs={24}
            style={{ width: "100%", height: "100%" }}
          >
            <MapContainer
              center={[45.5903637, 9.6119414]}
              zoom={13}
              scrollWheelZoom={false}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                position={[45.5903637, 9.6119414]}
                icon={
                  new Icon({
                    iconUrl: markerIconPng,
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                  })
                }
              >
                <Popup>
                  <strong>Landi S.p.A.</strong>
                  <br />
                  Strada Francesca, 2-2/A
                  <br />
                  24040 Ciserano (BG)
                  <br />
                  Italia
                </Popup>
              </Marker>
            </MapContainer>
          </Col>
        ) : null}
        <Col
          xxl={3}
          xl={4}
          lg={5}
          md={18}
          sm={24}
          xs={24}
          style={{
            fontSize: 16,
            textAlign: cookie === "true" ? "left" : "center",
          }}
        >
          <address>
            <strong>Landi S.p.A., con socio unico</strong>
            <br />
            Strada Francesca, 2-2/A
            <br />
            24040 Ciserano (BG) - Italia
            <br />
            <abbr title="Telefono:">T</abbr>: +39 0354182300
            <br />
            <abbr title="Email:">@</abbr>: landispa@landispa.eu
            <br />
            <abbr title="Posta Elettronica Certificata:">PEC</abbr>:
            landispa@legalmail.it
            <br />
            P. IVA 01604680163
            <br />
            REA di Bergamo n.222015
            <br />
            Cap. Soc. € 1.000.000,00 i.v.
            <br />
            Direzione e Coordinamento: Daikin Europe N.V.
          </address>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Home;
